.job-teaser {
  padding: var(--teaser-padding);
}

.job-teaser__description {
  color: $color-blue-1;
  line-height: $line-height-body;
  margin-top: 2.4rem;
}

.job-teaser__footer {
  border-top: 1px solid $color-blue-1;
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
  padding-top: 3.2rem;
}

.job-teaser__location {
  align-self: center;
  color: $color-blue-2;
  font-weight: $font-weight-bold-700;
  line-height: $line-height-body;
  margin-right: 1.6rem;
}

.job-teaser__action {
  margin-left: auto;
}
