:root {
  --button-background-color: #{$color-white};
  --button-border-color: var(--button-background-color);
  --button-border-size: 2px;
  --button-color: #{$color-blue-1};
  --button-focus-background-color: #{$color-blue-3};
  --button-focus-border-color: var(--button-focus-background-color);
  --button-focus-color: #{$color-blue-2};
  --button-line-height: 1.2;

  @include use-responsive-sizing(
    --button-height,
    responsive-map(xs 5.4rem, xl 6.4rem)
  );
  @include use-responsive-sizing(
    --button-font-size,
    responsive-map(xs 1.8rem, xl 2.2rem)
  );
}

.button {
  --button-text-height: calc(
    var(--button-font-size) * var(--button-line-height)
  );

  background-color: var(--button-background-color);
  border: var(--button-border-size) solid var(--button-border-color);
  border-radius: calc(var(--button-height) / 2);
  color: var(--button-color);
  display: inline-block;
  font-size: var(--button-font-size);
  font-weight: $font-weight-bold-700;
  line-height: var(--button-line-height);
  min-height: var(--button-height);
  padding: calc(
      (var(--button-height) - var(--button-text-height)) / 2 -
        var(--button-border-size)
    )
    calc(var(--button-font-size) * 2);
  text-align: center;
  transition-property: background-color, border-color, color, transform;
  user-select: none;

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus,
  .has-button-focus:hover &:not([disabled]),
  .has-button-focus:focus-within &,
  &.button--active {
    background-color: var(--button-focus-background-color);
    border-color: var(--button-focus-border-color);
    color: var(--button-focus-color);
  }

  &:active {
    transform: scale(0.95);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    margin-left: 2rem;
  }
}

.button--small {
  @include use-responsive-sizing(
    --button-height,
    responsive-map(xs 4.8rem, xl 5.4rem)
  );
  @include use-responsive-sizing(
    --button-font-size,
    responsive-map(xs 1.6rem, xl 1.8rem)
  );
}

.button--block {
  width: 100%;
}

.button--primary {
  --button-background-color: #{$color-blue-2};
  --button-border-color: #{$color-blue-2};
  --button-color: #{$color-white};
  --button-focus-background-color: #{$color-white};
  --button-focus-border-color: #{$color-blue-2};
  --button-focus-color: #{$color-blue-2};
}

.button--primary.button--inverted {
  --button-background-color: #{$color-white};
  --button-border-color: #{$color-white};
  --button-color: #{$color-blue-1};
  --button-focus-background-color: #{$color-blue-2};
  --button-focus-border-color: #{$color-blue-2};
  --button-focus-color: #{$color-white};
}

.button--secondary {
  --button-background-color: transparent;
  --button-border-color: #{$color-blue-2};
  --button-color: #{$color-blue-2};
  --button-focus-background-color: #{$color-blue-2};
  --button-focus-border-color: #{$color-blue-2};
  --button-focus-color: #{$color-white};
}

.button--secondary.button--inverted {
  --button-background-color: transparent;
  --button-border-color: #{$color-white};
  --button-color: #{$color-white};
  --button-focus-background-color: #{$color-white};
  --button-focus-border-color: #{$color-white};
  --button-focus-color: #{$color-blue-1};
}

.button--tertiary {
  --button-background-color: #{$color-blue-3};
  --button-border-color: #{$color-blue-3};
  --button-color: #{$color-blue-1};
  --button-focus-background-color: #{$color-blue-1};
  --button-focus-border-color: #{$color-blue-1};
  --button-focus-color: #{$color-white};
}

.button--quaternary {
  --button-background-color: #{$color-blue-4};
  --button-border-color: #{$color-blue-4};
  --button-color: #{$color-blue-1};
  --button-focus-background-color: #{$color-blue-1};
  --button-focus-border-color: #{$color-blue-1};
  --button-focus-color: #{$color-white};
}
