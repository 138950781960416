:root {
  --text-button-color: #{$color-blue-1};
  --text-button-icon-color: #{$color-blue-2};
  --text-button-hover-color: #{$color-blue-1};
  --text-button-icon-hover-color: #{$color-blue-1};
  --text-button-font-size: 1.8rem;
}

.text-button {
  --underline-color: var(--text-button-color);
  --underline-focus-color: var(--text-button-hover-color);

  align-items: center;
  color: var(--text-button-color);
  display: inline-flex;
  font-size: var(--text-button-font-size);
  font-weight: $font-weight-bold-700;
  line-height: 1.33;
  text-align: left;
  transition-property: color;
  user-select: none;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]):hover,
  &:focus {
    color: var(--text-button-hover-color);
  }
}

.text-button__icon {
  color: var(--text-button-icon-color);
  font-size: 1.11em;
  line-height: 1;
  opacity: var(--text-button-icon-opacity, 1);
  transition-property: color, opacity;

  .text-button:not([disabled]):hover &,
  .text-button:focus & {
    color: var(--text-button-icon-hover-color);
    opacity: var(--text-button-icon-hover-opacity, 1);
  }
}

.text-button__icon--left {
  margin-right: 0.44em;
}

.text-button__icon--right {
  margin-left: 0.44em;
  order: 1;
}
