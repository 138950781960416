.circle-graphic {
  background-color: $color-blue-1;
  color: $color-white;
  display: block;
  padding: 8rem 0;
  position: relative;
  width: 100%;
  &--sources {
    padding-bottom: 0;
  }

  &__inner {
    @include use-container();
    display: block;
    position: relative;
  }
  &__headline {
    &.headline {
      color: $color-white;
      font-weight: $font-weight-regular-400;
    }
  }
  &__graphic {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 10rem 6rem 8rem 6rem;
    padding: 2rem 0 8rem 0;
    position: relative;
    width: calc(100% - 12rem);
    @include mq($from: l) {
      padding: 8rem 0;
    }
  }
  &__circle {
    aspect-ratio: 1/1;
    border: 0.2rem solid $color-blue-2;
    border-radius: 50%;
    display: block;
    max-width: 40rem;
    position: relative;
    width: 90%;
    &-entries {
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
    }
    &-entry {
      background-color: $color-blue-1;
      border: 0.2rem solid $color-blue-2;
      border-radius: 50%;
      display: block;
      height: 8vw;
      max-height: 10rem;
      max-width: 10rem;
      min-height: 7rem;
      min-width: 7rem;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 8vw;
      &::before {
        background-color: $color-blue-2;
        content: '';
        display: block;
        position: absolute;
      }
      &::after {
        background-color: $color-orange;
        border-radius: 50%;
        color: $color-white;
        content: attr(data-count);
        display: block;
        font-size: 1.4rem;
        left: -2em;
        padding: 0.3em 0.5em;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-100%, -50%);
      }
      &__icon {
        display: block;
        color: $color-white;
        height: auto;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
      }
      &__title {
        background-color: $color-blue-1;
        bottom: -0.5em;
        color: $color-white;
        display: inline-block;
        font-size: 1.6rem;
        left: 50%;
        max-height: 1em;
        position: absolute;
        transform: translate(-50%, 100%);
        white-space: nowrap;
      }
      &__bullet-points {
        align-items: flex-start;
        display: none;
        flex-direction: column;
        list-style: none;
        max-width: 25rem;
        position: absolute;
        li {
          display: inline-block;
          line-height: 1.25em;
          margin: 0.5rem 0;
          padding-left: 1em;
          position: relative;
          &::before {
            background-color: $color-orange;
            border-radius: 50%;
            content: '';
            display: block;
            height: 0.6em;
            left: 0;
            position: absolute;
            top: 0.2em;
            width: 0.6em;
            z-index: $z-index-foreground;
          }
          &::after {
            background-color: $color-blue-2;
            content: '';
            display: block;
            height: calc(100% + 0.5rem + 0.4em);
            left: 0.25em;
            position: absolute;
            top: 0.2em;
            width: 0.2rem;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
        &--top {
          &::before {
            height: 3rem;
            left: calc(50% - 0.1rem);
            top: -3rem;
            width: 0.2rem;
          }
          &::after {
            height: 1.5em;
            left: 50%;
            top: -4rem;
            transform: translateX(-50%);
          }
          .circle-graphic__circle-entry__bullet-points {
            left: calc(100% + 2rem - 0.05em);
            top: -1rem;
            transform: translateY(-100%);
          }
        }
        &--right {
          &::before {
            height: 0.2rem;
            right: 0;
            top: calc(50% - 0.1rem);
            transform: translateX(100%);
            width: calc(3rem + 0.3em);
          }
          &::after {
            left: auto;
            right: -6rem;
          }
          .circle-graphic__circle-entry__bullet-points {
            left: calc(100% + 5rem);
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &--bottom {
          &::before {
            bottom: -6rem;
            height: 6rem;
            left: calc(50% - 0.1rem);
            width: 0.2rem;
          }
          &::after {
            bottom: -8rem;
            height: 1.5em;
            left: 50%;
            top: auto;
            transform: translateX(-50%);
          }
          .circle-graphic__circle-entry__bullet-points {
            bottom: -3.5rem;
            left: calc(-2rem + 0.05em);
            transform: translate(-100%, 100%);
          }
        }
        &--left {
          &::before {
            height: 0.2rem;
            left: 0;
            top: calc(50% - 0.1rem);
            transform: translateX(-100%);
            width: calc(3rem + 0.3em);
          }
          &:after {
            left: -2rem;
          }
          .circle-graphic__circle-entry__bullet-points {
            left: -5rem;
            top: 50%;
            transform: translate(-100%, -50%);
          }
        }
        &--mirrored {
          .circle-graphic__circle-entry__bullet-points {
            align-items: flex-end;
            li {
              padding-left: 0;
              padding-right: 1em;
              text-align: right;
              &::before {
                left: auto;
                right: 0;
              }
              &::after {
                left: auto;
                right: 0.25em;
              }
            }
          }
        }
      }
      &--no-bullet-points {
        &::before,
        &::after {
          display: none !important;
        }
      }
    }
    @include mq($from: m) {
      &-entry {
        &__title {
          font-size: 2rem;
        }
      }
    }
    @include mq($from: l) {
      width: 60%;
      &-entry {
        &::after {
          background-color: transparent;
          content: '';
          display: none;
          transform: translateY(-50%);
          z-index: $z-index-background;
        }
        &__bullet-points {
          display: flex;
          &--top,
          &--bottom {
            &::after {
              border: 0.2rem solid $color-blue-2;
              border-radius: 2rem;
              display: block;
              height: 5rem;
              width: 5rem;
            }
          }
          &--bottom {
            &::before {
              display: none;
            }
            &::after {
              clip-path: polygon(0 0, 50% 0, 50% 50%, 0 50%);
              left: -2.5rem;
              top: calc(50% + 2.5rem);
            }
            .circle-graphic__circle-entry__bullet-points {
              &::before {
                background-color: $color-blue-2;
                content: '';
                display: block;
                height: 7.5rem;
                position: absolute;
                right: 0.25em;
                top: -6.3rem;
                width: 0.2rem;
              }
            }
          }
          &--left,
          &--right {
            &::before {
              width: calc(5rem + 0.3em);
            }
          }
          &--top {
            &::before {
              display: none;
            }
            &::after {
              clip-path: polygon(50% 100%, 100% 100%, 100% 50%, 50% 50%);
              left: calc(100% - 2.5rem);
              top: calc(50% - 2.5rem);
            }
            .circle-graphic__circle-entry__bullet-points {
              &::before {
                background-color: $color-blue-2;
                bottom: -3.4rem;
                content: '';
                display: block;
                height: 4.5rem;
                position: absolute;
                left: 0.25em;
                width: 0.2rem;
              }
            }
          }
        }
      }
    }
  }
  &__icon {
    color: $color-orange;
    display: block;
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
  }
  &__bullet-points-mobile {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0;
    margin: 0 auto;
    position: relative;
    width: 60%;

    ul {
      display: block;
      list-style: none;
      margin-bottom: 3rem;
      position: relative;
      &::before {
        background-color: $color-orange;
        border-radius: 50%;
        color: $color-white;
        content: attr(data-count);
        display: block;
        font-size: 1.4rem;
        left: -2em;
        padding: 0.3em 0.5em;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-100%, -50%);
      }
      li {
        display: block;
        margin-bottom: 1rem;
        padding-left: 1em;
        position: relative;
        &::before {
          background-color: $color-orange;
          border-radius: 50%;
          content: '';
          display: block;
          height: 0.6em;
          left: 0;
          position: absolute;
          top: 0.2em;
          width: 0.6em;
          z-index: $z-index-foreground;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @include mq($from: l) {
      display: none;
    }
  }
  &__sources {
    background-color: $color-blue-2;
    color: $color-white;
    display: block;
    margin-top: 6rem;
    padding: 2rem 0;
    position: relative;
    width: 100%;
    &__inner {
      @include use-container();
      display: block;
      position: relative;
    }
  }
}
