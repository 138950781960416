.message {
  color: $color-ui-gray;
  display: flex;
  font-size: 1.4rem;
  line-height: $line-height-body;
}

.message--error {
  color: $color-red;
  font-weight: $font-weight-bold-700;
}

.message__icon {
  color: $color-gray;
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.8rem;
  margin-top: 0.4rem;

  .message--error & {
    color: $color-red;
  }
}
