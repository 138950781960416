.breadcrumb__items {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.4rem, xl 1.6rem));

  display: block;
  line-height: $line-height-body;
}

.breadcrumb__item {
  --link-color: #{$color-blue-1};
  --link-hover-color: #{$color-blue-1};

  display: inline;
  margin-right: 1.2rem;

  &:last-child {
    font-weight: $font-weight-medium-500;
    margin-right: 0;
  }
}

.breadcrumb__arrow {
  color: rgba($color-blue-1, 0.3);
  display: inline-block;
  font-size: 1.2rem;
  line-height: 0;
  margin-left: 1.2rem;
  vertical-align: 0.2rem;
}
