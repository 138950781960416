html {
  color: $root-color;
  font-family: $font-family;
  font-size: $root-font-size-px; // blame: this rule is an huge anti pattern, since it manipulates the rem base from 16px to 10px without using any responsive advantages. Why not using px then...
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: $font-weight-regular-400;
  height: 100%;
  line-height: 1;
  min-height: 100%;
  min-width: $root-min-width;
  scroll-behavior: smooth;
}

body {
  background-color: $body-background-color;
  font-size: $body-font-size;
  height: 100%;
  min-width: $root-min-width;
  overflow-x: hidden;
  overflow-y: scroll;

  &.no-scroll {
    overflow: hidden;
  }
}
