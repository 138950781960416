.external-content__container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  > * {
    flex-shrink: 0;
    max-width: 100%;
  }
}

.external-content__preview {
  background-color: $color-blue-3;
  min-height: 10rem;
  width: 100%;
}

.external-content__image {
  display: flex;
  filter: blur(10px);
  transform: scale(1.05);

  &::after {
    background-color: $color-blue-1;
    bottom: 0;
    content: '';
    left: 0;
    mix-blend-mode: multiply;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-extra-level-2;
  }
}

.external-content__image,
.external-content__iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: $z-index-foreground;
}

.external-content__show {
  color: $color-blue-1;
  font-size: 1.6rem;
  left: 50%;
  line-height: 1.6;
  max-width: 40rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: $z-index-extra-level-3;

  .external-content__image + & {
    color: $color-white;
  }
}

.external-content__button {
  margin-top: 1.6rem;
}

.external-content__caption {
  color: $color-blue-1;
  font-size: 1.4rem;
  font-weight: $font-weight-medium-500;
  line-height: $line-height-body;
  margin-top: 0.8rem;
}
