@use 'sass:math';
@use 'sass:map';

.ribbon-slider {
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 3.2rem, xl 5.4rem));

  background-color: $color-blue-3;
}

.ribbon-slider__inner {
  @include use-container();
}

.ribbon-slider__content {
  position: relative;
}

.ribbon-slider__headline {
  text-align: center;
}

.ribbon-slider__scroller {
  @include use-break-out();

  overflow: hidden;
}

.ribbon-slider__cards {
  display: flex;
  transform: translateX(0);
  transition-duration: $duration-long;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.ribbon-slider__card {
  --card-gap: 6.5rem;

  @include use-responsive-sizing(--card-padding, map.merge($inner-container-padding, (
    rem2px($inner-container-max-width): $inner-container-max-padding,
  )));

  color: $color-blue-1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: center;
  width: 100%;
}

.ribbon-slider__card-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.ribbon-slider__card-content {
  padding-left: 7rem;
  padding-right: 7rem;
}

.ribbon-slider__card-content-inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ribbon-slider__card-number {
  @include use-responsive-sizing(font-size, responsive-map(xs 9rem, xl 15rem));

  font-weight: $font-weight-medium-500;
  line-height: 10rem;
  margin-bottom: calc(-2.4rem + 0.4em);
  white-space: nowrap;
}

.ribbon-slider__card-image {
  height: 16rem;
  width: 16rem;

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }
}

.ribbon-slider__card-text {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2rem));

  flex-shrink: 1;
  line-height: $line-height-body;
  max-width: 40rem;

  &:not(:first-child) {
    margin-top: 2.4rem;
  }
}

.ribbon-slider__card-button {
  --button-background-color: transparent;
  --button-border-color: #{$color-blue-1};
  --button-color: #{$color-blue-1};
  --button-focus-background-color: #{$color-blue-1};
  --button-focus-border-color: #{$color-blue-1};
  --button-focus-color: #{$color-white};

  &:not(:first-child) {
    margin-top: 3.2rem;
  }
}

.ribbon-slider__more-link {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ribbon-slider__pager {
  display: none;
}

.ribbon-slider__navigation-button {
  background-color: $color-white;
  border-radius: 50%;
  color: $color-blue-1;
  font-size: 1.6rem;
  height: 3.2rem;
  line-height: 0;
  padding: 0.8rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition-property: background-color, color;
  width: 3.2rem;

  &[disabled] {
    color: $color-ui-gray;
    cursor: not-allowed;
  }

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-blue-1;
    color: $color-white;
  }
}

.ribbon-slider__navigation-button--prev {
  left: 0;
}

.ribbon-slider__navigation-button--next {
  right: 0;
}

@include mq($from: l) {
  .ribbon-slider__headline {
    text-align: left;
  }

  .ribbon-slider__card {
    position: relative;
    width: 50%;

    &::before {
      background-color: $color-blue-2;
      content: '';
      height: 1.6rem;
      left: 0;
      position: absolute;
      right: 0;
      top: 12rem;
    }
  }

  .ribbon-slider__card:nth-child(2n+1) {
    &::before {
      right: 3rem;
    }

    &::after {
      content: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 200"><path d="M.6 0a40 40 0 0140 39.3V160a24 24 0 0023.6 24h.4v16a40 40 0 01-40-39.3V40A24 24 0 001 16H.6V0z" fill="' + $color-blue-2 + '" fill-rule="nonzero"/></svg>');
      height: 20rem;
      left: 100%;
      position: absolute;
      top: 12rem;
      transform: translateX(-50%);
      width: 6.5rem;
    }
  }

  .ribbon-slider__card:nth-child(2n+2) {
    &::before {
      left: 3rem;
    }
  }

  .ribbon-slider__card:nth-child(4n-1) {
    &::after {
      transform: translateX(-50%) scale(-1, 1);
    }
  }

  .ribbon-slider__card:nth-child(4n+2),
  .ribbon-slider__card:nth-child(4n+3) {
    padding-top: 18rem;

    &::before {
      top: 30.4rem;
    }
  }

  .ribbon-slider__card:last-child {
    &::after {
      display: none;
    }
  }

  .ribbon-slider__card--last-uneven-card {
    &::before {
      right: -100% !important;
    }
  }

  .ribbon-slider__card-inner {
    justify-content: flex-start;
    min-height: 20rem;
    padding-top: 8rem;
    position: relative;

    .ribbon-slider__card:nth-child(2n+1) & {
      padding-left: var(--card-padding);
      padding-right: var(--card-gap);
    }

    .ribbon-slider__card:nth-child(2n+2) & {
      padding-left: var(--card-gap);
      padding-right: var(--card-padding);
    }
  }

  .ribbon-slider__card-content {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .ribbon-slider__card-number {
    margin-top: -1rem;
    background-color: $color-blue-3;
    position: relative;

    &::before,
    &::after {
      width: 4rem;
      background-color: $color-blue-3;
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
    }

    &::before {
      left: -4rem;
    }

    &::after {
      left: 100%;
    }
  }

  .ribbon-slider__card-main {
    align-items: center;
    background-color: $color-blue-3;
    display: flex;
    flex-direction: column;
  }

  .ribbon-slider__card-image,
  .ribbon-slider__card-headline,
  .ribbon-slider__card-text {
    background-color: $color-blue-3;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ribbon-slider__card-headline,
  .ribbon-slider__card-text {
    max-width: 48rem;
  }

  .ribbon-slider__card-image {
    margin-top: -3.5rem;
    width: 24rem;
  }

  .ribbon-slider__more-link {
    height: 3.4rem;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
  }

  .ribbon-slider__pager {
    display: flex;
    justify-content: center;
  }

  .ribbon-slider__navigation-button {
    display: none;
  }
}

@include mq($from: rem2px($inner-container-max-width)) {
  .ribbon-slider__card-inner {
    max-width: math.div($inner-container-max-width, 2);

    .ribbon-slider__card:nth-child(2n+1) & {
      margin-left: auto;
    }

    .ribbon-slider__card:nth-child(2n+2) & {
      margin-right: auto;
    }
  }
}
