.field {
  line-height: 1;
  text-align: left;
}

.field__box {
  background-color: $color-white;
  border: 2px solid $color-gray;
  border-radius: 4px;
  padding: 1.2rem;
  position: relative;
  transition-property: border-color;

  &:hover {
    border-color: $color-blue-1;
  }

  &:focus-within {
    border-color: $color-blue-2;
  }
}

.field__box--has-error {
  border-color: $color-red;
}

.field__box-label {
  color: $color-ui-gray;
  cursor: pointer;
  display: block;
  font-size: 1.2rem;
  font-weight: $font-weight-bold-700;
  margin-bottom: 1.2rem;

  &::after {
    content: '';
    inset: 0;
    position: absolute;
    z-index: $z-index-foreground;
  }
}

.field__box-input {
  background-color: transparent;
  border-radius: 0.5rem;
  color: $color-blue-1;
  display: block;
  font-size: 1.8rem;
  height: auto;
  line-height: 2.4rem;
  min-width: 100%;
  padding: 0;
  position: relative;
  transition-property: box-shadow;
  width: 100%;
  z-index: $z-index-extra-level-2;

  &::placeholder {
    color: $color-ui-gray;
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }
}

.field__box-input--select {
  cursor: pointer;
}

.field__box-input--color {
  cursor: pointer;
  height: 5.4rem;
  padding: 0.5rem;
}

.field__box-input--number {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.field__box-input--hidden {
  display: none;
}

.field__box-input--has-icon {
  padding-right: 2.4rem;
}

.field__box-option {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: $font-weight-regular-400;

  &[disabled] {
    cursor: not-allowed;
  }
}

.field__box-icon {
  bottom: 1.8rem;
  color: $color-gray;
  font-size: 1.8rem;
  height: 1.8rem;
  line-height: 0;
  pointer-events: none;
  position: absolute;
  right: 1.6rem;
  width: 1.8rem;
  z-index: $z-index-extra-level-3;
}

.field__limit {
  border-radius: 1.2rem;
  color: $color-white;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  margin-top: 1.2rem;
  opacity: 0.3;
  padding: 0.4rem 1rem;
  transition-property: opacity;

  .field:focus-within & {
    opacity: 1;
  }
}

.field__legend {
  color: $color-ui-gray;
  display: block;
  font-size: 1.2rem;
  font-weight: $font-weight-bold-700;
  margin-bottom: 1.2rem;
}

.field__line {
  cursor: pointer;
  display: flex;
  user-select: none;

  &:focus-within {
    @include use-outline();
  }

  & + & {
    margin-top: 0.8rem;
  }
}

.field__checkbox,
.field__radio {
  flex-shrink: 0;
  position: relative;
}

.field__checkbox-input,
.field__radio-input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: $z-index-background;

  &:focus {
    outline: 0;
  }
}

.field__checkbox-icon {
  border: 2px solid $color-gray;
  border-radius: 0.4rem;
  color: transparent;
  cursor: pointer;
  font-size: 1.4rem;
  height: 2rem;
  line-height: 0;
  padding: 0.1rem;
  transition-property: background-color, border-color, color;
  user-select: none;
  width: 2rem;

  .field__line:hover & {
    border-color: $color-blue-2;
  }

  .field__checkbox-input:checked ~ & {
    background-color: $color-blue-2;
    border-color: $color-blue-2;
    color: $color-white;
  }
}

.field__radio-icon {
  border: 2px solid $color-gray;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  padding: 0.5rem;
  position: relative;
  user-select: none;
  width: 2rem;

  &::after {
    background-color: $color-blue-2;
    border-radius: 50%;
    content: '';
    height: 1.2rem;
    left: 0.2rem;
    opacity: 0;
    position: absolute;
    top: 0.2rem;
    transition-property: opacity;
    width: 1.2rem;
  }

  .field__line:hover &,
  .field__radio-input:checked ~ & {
    border-color: $color-blue-2;
  }

  .field__radio-input:checked ~ &::after {
    opacity: 1;
  }
}

.field__line-label {
  align-self: center;
  color: $color-blue-1;
  flex-grow: 2;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-left: 1.2rem;
}

.field__extra-content {
  margin-top: 1rem;
}

.field__error,
.field__description {
  margin-top: 1.2rem;
}
