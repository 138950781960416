:root {
  --form-submit-align: flex-start;
}

.form {
  font-size: 1.6rem;
  line-height: $line-height-body;

  .alert + & {
    margin-top: 3.2rem;
  }
}

.form__headline,
.form__progress,
.form__alert {
  margin-bottom: 3.2rem;
}

.form__progress:last-child {
  margin-bottom: 0;
  margin-top: 3.2rem;
}

.form__inner {
  @include mq($from: m) {
    display: flex;
  }
}

.form__tabs {
  flex-shrink: 0;
  margin-bottom: 3.2rem;
  min-width: 20rem;
  padding-left: 2.4rem;

  @include mq($from: m) {
    margin-bottom: 0;
    margin-right: 2.4rem;
    width: 20%;
  }
}

.form__tab {
  opacity: 0.5;
  padding-left: 1rem;
  position: relative;

  & + & {
    margin-top: 0.8rem;
  }

  &::before {
    background-color: $color-blue-2;
    border-radius: 50%;
    content: '';
    height: 2.4rem;
    left: -2.4rem;
    position: absolute;
    top: 0.1rem;
    width: 2.4rem;
    z-index: $z-index-background;
  }

  &::marker {
    color: $color-white;
    width: 2.4rem;
  }
}

.form__tab--active {
  font-weight: $font-weight-bold-700;
  opacity: 1;
}

.form__pages {
  flex-grow: 1;
}

.form__buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--form-submit-align);
  margin: -1.6rem;
  padding-top: 3.2rem;

  > * {
    padding: 1.6rem;
  }
}

.form__back {
  margin-left: auto;
}
