:root {
  --quote-flex-align: center;
  --quote-text-align: center;
  --quote-color: #{$color-blue-1};
  --quote-icon-color: #{$color-blue-2};
}

.quote {
  @include use-container($max-width: $max-width-content-medium);
  align-items: var(--quote-flex-align);
  color: var(--quote-color);
  display: flex;
  flex-direction: column;
  line-height: $line-height-body;
  text-align: var(--quote-text-align);
}

.quote__icon {
  @include use-responsive-sizing(
    font-size,
    responsive-map(xs 3.2rem, xl 5.6rem)
  );

  color: var(--quote-icon-color);
  line-height: 0;
  margin-bottom: 1.2rem;

  &.animated {
    transform: scale(2);

    &.visible {
      animation: shrinkIcon 0.5s ease-in forwards;
    }
  }
}

.quote__quote {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.4rem, xl 4rem));

  &.animated {
    overflow: hidden;

    &.visible p {
      animation: revealText 0.3s ease forwards;
    }
  }

  &.animated {
    p {
      transform-origin: top;
      opacity: 0;

      &:nth-child(1) {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
      &:nth-child(4) {
        animation-delay: 0.8s;
      }
      &:nth-child(5) {
        animation-delay: 1s;
      }
      &:nth-child(6) {
        animation-delay: 1.2s;
      }
      &:nth-child(7) {
        animation-delay: 1.4s;
      }
    }
  }
}

.quote__caption {
  @include use-responsive-sizing(
    font-size,
    responsive-map(xs 1.4rem, xl 2.2rem)
  );

  font-weight: $font-weight-medium-500;
  margin-top: 2.4rem;

  &.animated {
    transform-origin: top;
    opacity: 0;

    &.visible {
      animation: revealText 0.3s ease forwards;
      animation-delay: 1s;
    }
  }
}

@keyframes revealText {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes shrinkIcon {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}
