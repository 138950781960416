.page-head {
  background-color: $color-blue-3;
  color: $color-blue-1;
  position: relative;
}

.page-head--inverted {
  --headline-color: #{$color-white};

  background-color: $color-blue-1;
  color: $color-white;
}

.page-head__image {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: $z-index-foreground;
}

.page-head__inner {
  @include use-container();
  @include use-responsive-sizing(
    padding-top,
    responsive-map(xs 15rem, xl 25rem)
  );
  @include use-responsive-sizing(
    padding-bottom,
    responsive-map(xs 5rem, xl 10rem)
  );

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  text-align: center;
  z-index: $z-index-extra-level-2;
}

.page-head__content {
  @include use-responsive-sizing(
    width,
    (
      xs: column-width(xs, 12),
      s: column-width(s, 10),
      m: column-width(m, 8),
      l: column-width(l, 7),
      xl: column-width(xl, 6),
    )
  );
}

.page-head__description {
  @include use-responsive-sizing(font-size, responsive-map(xs 2rem, xl 2.8rem));

  line-height: $line-height-body;
  margin-top: 3.2rem;
}

.page-head__icon {
  @include use-responsive-sizing(
    font-size,
    responsive-map(xs 1.8rem, xl 2.6rem)
  );

  background-color: $color-blue-2;
  border-radius: 50%;
  color: $color-white;
  height: 2em;
  line-height: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  padding: 0.5em;
  width: 2em;
}
