.gallery {
  position: relative;
}

.gallery__container {
  margin: -1rem;
  overflow: hidden;
}

.gallery__images {
  align-items: stretch;
  display: flex;
  transform: translateX(0);
  transition-property: transform;
}

.gallery__image {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 1rem;
  width: 100%;
}

.gallery__image-container {
  background-color: $color-blue-3;
  display: flex;
  flex-grow: 1;
}

.gallery__image-container-inner {
  margin: auto;
  position: relative;
}

.gallery__image-copyright {
  bottom: 0;
  position: absolute;
  right: 0;
}

.gallery__image-caption {
  color: $color-blue-1;
  font-size: 1.4rem;
  font-weight: $font-weight-medium-500;
  line-height: $line-height-body;
  margin-top: 0.8rem;
  text-align: left;
}

.gallery__navigation-button {
  background-color: $color-white;
  border-radius: 50%;
  color: $color-blue-1;
  font-size: 1.6rem;
  height: 3.2rem;
  line-height: 0;
  padding: 0.8rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition-property: background-color, color;
  width: 3.2rem;

  &[disabled] {
    color: $color-ui-gray;
    cursor: not-allowed;
  }

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-blue-1;
    color: $color-white;
  }
}

.gallery__navigation-button--prev {
  left: 2.4rem;
}

.gallery__navigation-button--next {
  right: 2.4rem;
}
