/* naming convention see: https://learn.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass */
@font-face {
  font-display: swap;
  font-family: 'ATRUVIA';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/atruvia-light.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'ATRUVIA';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/atruvia-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'ATRUVIA';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/atruvia-medium.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'ATRUVIA';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/atruvia-bold.woff2') format('woff2');
}
