$columnGaps: (
  xs: 1rem,
  m: 2rem,
);

.content-guide {
  --headline-color: #{$color-white};
  color: $color-white;

  &.position {
    &-top {
      @include use-responsive-sizing(
        padding-top padding-bottom,
        responsive-map(xs 4rem, xl 7.2rem)
      );
      background-color: $color-blue-1;
      margin-bottom: 11rem;

      @include mq($from: s) {
        margin-bottom: 16rem;
      }

      @include mq($from: m) {
        margin-bottom: 24rem;
      }

      @include mq($from: l) {
        margin-bottom: 30rem;
      }
    }

    &-mid {
      background: $color-blue-3;
      color: $color-blue-1;
      padding-bottom: 4rem;
      padding-top: 4rem;

      .content-guide__container {
        margin-bottom: 0;
      }

      .content-guide__item {
        border-color: #0000641a;
      }

      .headline__text,
      .content-guide__headline,
      .content-guide__subline,
      .content-guide__item-title {
        color: $color-blue-1;
      }

      .bg-block {
        display: none;
      }
    }

    &-bottom {
      background: $color-blue-1;
      margin-top: 11rem;

      .bg-block {
        background: $color-blue-1;
        bottom: -130px;
        display: none;
        //height: 25%;
        //min-height: 35vh;
        min-height: 20rem;
        position: relative;
        width: 100%;
      }

      margin-bottom: 0;
      padding-bottom: 4rem;
      padding-top: 4rem;

      //&.content-guide__grids-4,
      //&.content-guide__grids-5 {
      //  @include mq($from: m) {
      //    padding-bottom: 120px;
      //  }
      //}

      @include mq($from: m) {
        background: $color-white;
        padding-bottom: 130px;
        padding-top: 0;

        .content-guide__headline .headline {
          color: $color-blue-1;
        }

        .content-guide__subline {
          color: $color-blue-1;
        }

        .content-guide__item-title {
          color: $color-white;
        }
      }

      @include mq($from: m) {
        min-height: 35vh;
        padding-bottom: 125px;

        .bg-block {
          bottom: -125px;
          display: block;
        }
      }

      @include mq($from: xl) {
        padding-bottom: 90px;

        .bg-block {
          bottom: -90px;
        }
      }

      .content-guide__container {
        @include mq($from: m) {
          margin-bottom: -25rem;
        }
      }

      &.content-guide__grids {
        &-3 {
          padding-bottom: 125px;

          .bg-block {
            bottom: -125px;
          }
        }
      }

      .content-guide__grid-5 {
      }
    }
  }

  &__container {
    @include use-container($max-width: $max-width-content-medium);

    @include mq($from: m) {
      margin-bottom: -20rem;
      text-align: center;
    }

    @include mq($from: l) {
      margin-bottom: -25rem;
    }
  }

  &__subline {
    @include use-responsive-sizing(
      font-size,
      responsive-map(xs 1.6rem, xl 2.2rem)
    );
    line-height: $line-height-body;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-top: 1.6rem;
    margin-right: auto;
    max-width: $max-width-text-mobile;

    @include mq($from: s) {
      margin-bottom: 6rem;
    }

    @include mq($from: l) {
      max-width: $max-width-text-desktop;
    }
  }

  &__items {
    @include use-responsive-sizing(column-gap, $columnGaps);

    @include mq($from: m) {
      @include use-responsive-sizing(row-gap, map-multiply($gaps, 1.5));
    }

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(var(--teaser-grid-columns), 1fr);
  }

  &__grid {
    &-3 {
      @include use-responsive-sizing(
        --teaser-grid-columns,
        (
          xs: 1,
          m: 3,
        )
      );
    }

    &-4 {
      @include use-responsive-sizing(
        --teaser-grid-columns,
        (
          xs: 1,
          m: 4,
        )
      );
    }

    &-5 {
      @include use-responsive-sizing(
        --teaser-grid-columns,
        (
          xs: 1,
          m: 5,
        )
      );
    }
  }

  &__item {
    align-items: center;
    border-bottom: 1px solid #ffffff4d;
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    padding: 3rem 0;
    overflow: hidden;
    position: relative;
    z-index: $z-index-foreground;

    @include mq($from: m) {
      align-items: start;
      border: 0;
      flex-direction: column;
      padding-top: 0;
    }

    .image {
      height: 100%;
      transition-duration: $duration-long;
      transition-property: transform;
      width: 10rem;

      @include mq($from: m) {
        max-width: none;
        width: auto;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover .image,
    &:focus-within .image {
      transform: scale(1.05);
    }

    .figure {
      padding-left: 2rem;
    }

    .figure,
    .figure__figure {
      overflow: hidden;
      aspect-ratio: auto 1/1;
    }

    @include mq($from: m) {
      .figure {
        padding-left: 0;
      }

      .figure,
      .figure__figure {
        aspect-ratio: auto 3/4;
      }
    }

    &-title {
      @include use-responsive-sizing(
        font-size,
        responsive-map(xs 1.6rem, xl 2.2rem)
      );

      -moz-hyphens: auto;
      -o-hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      overflow-wrap: break-word;

      color: $color-white;
      line-height: $line-height-body;
      margin-right: auto;
      text-align: left;
      width: 100%;
      z-index: $z-index-foreground;

      @include mq($from: m) {
        color: $color-blue-1;
        margin-right: 0;
        padding-top: 1.6rem;
      }
    }
  }
}
