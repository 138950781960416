@keyframes modal-fadein-background {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-fadein-content {
  0% {
    opacity: 0;
    transform: translate(-50%, calc(-50% - 2rem));
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.modal {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: z-index('modal');

  &[aria-hidden='true'] {
    display: none;
  }
}

.modal__background {
  animation: modal-fadein-background 0.2s;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-foreground;
}

.modal__modal {
  animation: modal-fadein-content 0.2s;
  background-color: #fff;
  border-radius: 0.5rem;
  left: 50%;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: $z-index-extra-level-2;
}

.modal__modal-inner {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  max-width: 70rem;
  width: 90vw;
}

.modal__header {
  align-items: center;
  background-color: $color-blue-1;
  color: $color-white;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 2.4rem;
}

.modal__title {
  font-size: 1.8rem;
  font-weight: $font-weight-medium-500;
}

.modal__close {
  border-radius: 50%;
  flex-shrink: 0;
  font-size: 1.6rem;
  height: 3.4rem;
  line-height: 0;
  margin-left: 2.4rem;
  padding: 0.8rem;
  transition-property: background-color;
  width: 3.4rem;

  &:hover,
  &:focus {
    background-color: $color-black;
  }
}

.modal__content {
  overflow-y: auto;
  padding: 2.4rem;
}
