.teaser-list {
  @include use-responsive-sizing(
    padding-top padding-bottom,
    responsive-map(xs 4rem, xl 7.2rem)
  );

  background-color: $color-blue-3;
}

.teaser-list__inner {
  @include use-container();
}

.teaser-list__content {
  @include use-responsive-sizing(
    width,
    (
      m: column-width(m, 10),
      l: column-width(l, 8),
      xl: column-width(xl, 6),
    )
  );

  margin-left: auto;
  margin-right: auto;
}

.teaser-list__headline {
  margin-bottom: 4rem;
}

.teaser-list__teaser {
  background-color: $color-white;

  & + & {
    margin-top: 3.2rem;
  }
}

.teaser-list__more-link {
  @include use-responsive-sizing(
    font-size,
    (
      xs: 1.8rem,
      m: 2rem,
      l: 2.2rem,
    )
  );

  align-items: center;
  background-color: $color-white;
  color: $color-blue-1;
  display: flex;
  font-weight: $font-weight-medium-500;
  justify-content: flex-start;
  margin-top: 4rem;
  padding: var(--teaser-padding);
}

.teaser-list__more-link-icon {
  color: $color-blue-2;
  font-size: 1.5em;
  line-height: 0;
  margin-right: 2.4rem;
}
