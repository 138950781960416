.accordion {
  border: $border-boxes;
  color: $color-blue-1;
  transition-property: background-color, border-color;

  &[open] {
    background-color: $color-blue-4;
    border-color: $color-blue-4;
  }
}

.accordion__summary {
  appearance: none;
  display: block;
  font-size: 2.2rem;
  font-weight: $font-weight-medium-500;
  line-height: $line-height-body;
  margin-right: 2.4rem;
  user-select: none;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
}

.accordion__summary-inner {
  @include use-responsive-sizing(padding, responsive-map(xs 2.4rem, xl 4rem));

  align-items: center;
  cursor: pointer;
  display: flex;
}

.accordion__summary-icon {
  flex-shrink: 0;
  font-size: 3rem;
  line-height: 0;
  margin-left: auto;
  transition-property: transform;

  .accordion[open] & {
    transform: rotate(180deg);
  }
}

.accordion__content {
  @include use-responsive-sizing(
    padding-left padding-right padding-bottom,
    responsive-map(xs 2.4rem, xl 4rem)
  );

  > * + * {
    margin-top: 2rem;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.accordion__horizontal-rule,
.accordion__aside {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.accordion__heading {
  margin-top: 3.2rem;

  + .accordion__paragraph {
    margin-top: 1.2rem;
  }
}

.accordion__horizontal-rule {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.paragraph.accordion__paragraph {
  font-size: 1.8rem;
}
