.figure {
  display: block;
}

.figure__figure {
  position: relative;
}

.figure__copyright {
  bottom: 0;
  position: absolute;
  right: 0;
}

.figure__caption {
  color: $color-blue-1;
  font-size: 1.4rem;
  font-weight: $font-weight-medium-500;
  line-height: $line-height-body;
  margin-top: 0.8rem;
  text-align: left;
}
