.footer {
  background-color: $color-blue-3;
  border-top: 5px solid darken($color-blue-3, 5%);
}

.footer__bar {
  background-color: darken($color-blue-3, 5%);
}

.footer__bar-inner {
  @include use-container();

  display: flex;
  justify-content: space-between;
  padding-bottom: 2.4rem;
  padding-top: 1.6rem;
}

.footer__edit-button {
  --text-button-font-size: 1.6rem;

  flex-shrink: 0;
  margin-left: 1.6rem;
}

.footer__inner {
  @include use-container();
  @include use-responsive-sizing(padding-top, responsive-map(xs 3.2rem, xl 6.4rem));
  @include use-responsive-sizing(padding-bottom, responsive-map(xs 4rem, xl 9rem));

  color: $color-blue-1;
}

.footer__logo {
  @include use-responsive-sizing(margin-bottom, responsive-map(xs 4rem, xl 9rem));
  @include use-responsive-sizing(max-width, responsive-map(xs 25rem, xl 35rem));

  margin-left: auto;
  margin-right: auto;
}

.footer__navigation {
  --link-color: #{$color-blue-1};
  --link-hover-color: #{$color-blue-2};

  @include use-responsive-sizing(margin-left margin-right, map-multiply($gaps, -0.5));
  @include use-responsive-sizing(margin-top margin-bottom, map-multiply($gaps, -0.75));

  display: flex;
  flex-wrap: wrap;
  text-align: center;

  @include mq($from: s) {
    text-align: left;
  }
}

.footer__navigation-item {
  @include use-responsive-sizing(padding-left padding-right, map-multiply($gaps, 0.5));
  @include use-responsive-sizing(padding-top padding-bottom, map-multiply($gaps, 0.75));
  @include use-responsive-sizing(width, (
    xs: 100%,
    s: 50%,
    l: 25%,
  ));

  display: block;
  flex-shrink: 0;
}

.footer__navigation-item-headline {
  @include use-responsive-sizing(font-size, (
    xs: 2rem,
    s: 2.2rem,
    l: 2.4rem,
    xl: 2.6rem,
  ));

  font-weight: $font-weight-medium-500;
}

.footer__navigation-children {
  margin-top: 2.4rem;
}

.footer__navigation-child {
  @include use-responsive-sizing(font-size, (
    xs: 1.6rem,
    m: 1.8rem,
    l: 2rem,
  ));

  display: block;
  line-height: $line-height-body;

  & + & {
    margin-top: 0.8rem;
  }
}

.footer__social-media-accounts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.8rem;
  padding-top: 2.4rem;

  @include mq($from: s) {
    justify-content: flex-start;
  }
}

.footer__social-media-account {
  border: 1px solid $color-blue-1;
  display: block;
  margin: 0.8rem;
}

.footer__imprint-navigation {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  background-color: $color-blue-1;
}

.footer__imprint-navigation-inner {
  @include use-container();
}

.footer__imprint-navigation-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.8rem -1.6rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;

  @include mq($from: s) {
    justify-content: flex-start;
  }
}

.footer__imprint-navigation-item {
  display: block;
  padding: 0.8rem 1.6rem;
}
