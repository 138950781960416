.table {
  @include font-paragraph-small;

  border-collapse: collapse;
  color: $color-blue-1;
  line-height: $line-height-body;
  width: 100%;

  th,
  td {
    padding: 1.6rem 2.4rem;
    text-align: left;
    vertical-align: text-top;
  }

  th {
    background-color: $color-blue-1;
    color: $color-white;
    font-weight: $font-weight-bold-700;
  }

  tr:nth-child(odd) {
    background-color: $color-blue-4;
  }

  tr:nth-child(even) {
    background-color: $color-blue-3;
  }
}
