.infobox {
  @include use-responsive-sizing(
    padding-top padding-bottom,
    responsive-map(xs 4rem, xl 7.2rem)
  );

  background-color: $color-blue-3;
}

.infobox__container {
  @include use-container();
}

.infobox__headline {
  flex-shrink: 0;
  width: 100%;
}

.infobox__content {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width-text-mobile;

  @include mq($from: l) {
    max-width: $max-width-text-desktop;
  }

  > * + * {
    @include use-responsive-sizing(margin-top, $gaps);
  }
}

.infobox__image {
  position: relative;
}

.infobox__copyright {
  bottom: 0;
  position: absolute;
  right: 0;
}

.infobox__image,
.infobox__image + .infobox__text {
  @include use-responsive-sizing(
    width,
    (
      s: column-width(s, 12),
      m: column-width(m, 6),
      l: column-width(l, 6),
      xl: column-width(xl, 5, $number-of-columns: 10),
    )
  );
}

.infobox__text {
  > * + * {
    margin-top: 2rem;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.infobox__horizontal-rule {
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  width: 60%;
}

.infobox__links {
  margin-top: 4rem;
}

.infobox__link {
  display: block;

  & + & {
    margin-top: 1rem;
  }
}
