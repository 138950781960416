.image-slide {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: $z-index-foreground;
}

.image-slide--blue-1 {
  background-color: $color-blue-1;
}

.image-slide--blue-2 {
  background-color: $color-blue-2;
}

.image-slide--blue-3 {
  background-color: $color-blue-3;
}

.image-slide--large {
  @include use-responsive-sizing(
    min-height,
    responsive-map(xs 55rem, xl 92rem)
  );
}

.image-slide__inner {
  @include use-container();
  @include use-responsive-sizing(
    padding-top padding-bottom,
    responsive-map(xs 10rem, xl 15rem)
  );

  align-items: center;
  display: flex;
  justify-content: center;
}

.image-slide__image {
  display: inline-block;
  max-width: 32rem;

  @include mq($from: m) {
    max-width: 58rem;
  }

  @include mq($from: l) {
    max-width: 92rem;
  }
}

.image-slide__image-src {
  max-width: 100%;
  height: auto;
}
