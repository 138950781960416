@keyframes panorama-overlay-image-loader-spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.panorama-overlay {
  background-color: rgba($color-black, 0.33);
  display: block;
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $z-index-extra-level-6;
}
.panorama-overlay__window {
  background-color: $color-white;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 90%;
  max-width: 36rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;

  @include mq($from: l) {
    max-height: 65rem;
  }

  @media screen and (max-height: 65rem) {
    max-height: 90%;
  }
}
.panorama-overlay__window__header {
  align-items: center;
  display: flex;
  height: 5.6rem;
  min-height: 5.6rem;
  justify-content: flex-end;
  padding: 0 1.7rem;
  position: relative;
  width: 100%;
}
.panorama-overlay__window__closer {
  background-color: $color-blue-1;
  border-radius: 50%;
  color: $color-blue-3;
  display: block;
  height: 3.2rem;
  position: relative;
  transition-property: background-color, transform;
  width: 3.2rem;

  &::before,
  &::after {
    background-color: currentColor;
    content: '';
    display: block;
    height: 0.15rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 1.2rem;
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  span {
    display: inline-block;
    text-indent: -9999rem;
    position: relative;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
  }
}
.panorama-overlay__window__content {
  display: block;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.panorama-overlay__window__content__sheet {
  color: $color-blue-1;
  display: none;
  font-size: 1.4rem;
  line-height: 1.42em;
  padding: 0 3.8rem;
  &.active {
    display: block;
  }
}
.panorama-overlay__block {
  & + & {
    margin-top: 1.5rem;
  }
}
.panorama-overlay__headline {
  text-align: center;
  .headline {
    font-weight: $font-weight-bold-700;
  }

  & + .panorama-overlay__text-block {
    margin-top: 0;
  }
}
.panorama-overlay__big-headline {
  text-align: center;
  .headline {
    font-size: 6.8rem;
    font-weight: $font-weight-medium-500;
  }

  & + .panorama-overlay__text-block {
    margin-top: 0;
  }
}
.panorama-overlay__text-block {
  text-align: center;
}
.panorama-overlay__hl {
  background-color: currentColor;
  display: block;
  height: 0.1rem;
  position: relative;
  width: 100%;
}
.panorama-overlay__image {
  background-color: $color-blue-4;
  display: block;
  max-height: 19.5rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  .image {
    &::before {
      animation: panorama-overlay-image-loader-spin 1s infinite;
      border: 0.2rem solid $color-blue-2;
      border-radius: 50%;
      border-top: 0.2rem solid transparent;
      content: '';
      display: block;
      left: calc(50% - 1.5rem);
      height: 3rem;
      position: absolute;
      top: calc(50% - 1.5rem);
      width: 3rem;
    }
    &.image-loaded {
      &::before {
        display: none;
      }
    }
  }
  img {
    height: auto;
    width: 100%;
  }
}
.panorama-overlay__yt-video {
  display: block;
  height: 19.5rem;
  position: relative;
  width: 100%;

  iframe {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
  }
}
.panorama-overlay__yt-video__loader-image {
  background-size: cover;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  button {
    background-color: transparent;
    border: 0.2rem solid $color-white;
    border-radius: 50%;
    display: block;
    height: 5.4rem;
    left: 50%;
    position: absolute;
    text-indent: -9999rem;
    top: 50%;
    transform: translate(-50%, -50%);
    transition-property: transform;
    width: 5.4rem;
    z-index: $z-index-extra-level-2;

    &::before {
      border: 1.3rem solid $color-white;
      border-color: transparent transparent transparent $color-white;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(4%, -50%) scale(1.5, 1);
    }

    @media (hover: hover) {
      &:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  @include mq($from: l) {
    @media (hover: hover) {
      &:hover .panorama-overlay__yt-video__loader-image__hint {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .play & {
    display: none;
  }
}
.panorama-overlay__yt-video__loader-image__hint {
  background-color: rgba($color-white, 0.75);
  bottom: 0;
  color: $color-blue-1;
  display: block;
  font-size: 1.1rem;
  left: 0;
  line-height: 1.3em;
  opacity: 1;
  padding: 0.5rem 1rem;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: $z-index-extra-level-1;

  @include mq($from: l) {
    opacity: 0;
    pointer-events: none;
    transition-property: opacity;
  }
}

.panorama-overlay__window__controls {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2.1rem 2.8rem;
  position: relative;
  width: 100%;

  button {
    background-color: $color-blue-3;
    border-radius: 50%;
    color: $color-blue-1;
    display: block;
    height: 3.2rem;
    margin: 0 1rem;
    position: relative;
    text-indent: -9999rem;
    transition-property: transform;
    width: 3.2rem;
    &:disabled {
      opacity: 0;
      pointer-events: none;
    }
    .icon {
      color: currentColor;
      display: block;
      font-size: 1.3rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @media (hover: hover) {
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .panorama-overlay__window__controls__vr {
    align-items: center;
    display: none;
    flex-direction: column;
    position: absolute;
    right: -4.5rem;
    top: 50%;
    transform: translateY(-50%);

    button {
      margin: 0.5rem 0;
    }
  }
}

.panorama-overlay--vr {
  width: 50%;
}
.panorama-overlay--vr .panorama-overlay__window {
  left: 42.5%;
  max-height: 70%;
  max-width: 65%;
  top: 42%;
}
.panorama-overlay--vr .panorama-overlay__window__content__sheet {
  font-size: 1.1rem;
  padding: 1rem 2rem;
}
.panorama-viewer.panorama-viewer--vr-working.panorama-viewer--vr-overlay-single
  .panorama-overlay__window__content__sheet {
  display: block;
}
.panorama-overlay--vr .panorama-overlay__headline .headline--3 {
  font-size: 2.3rem;
}
.panorama-overlay--vr .panorama-overlay__big-headline .headline--1 {
  font-size: 3rem;
}
.panorama-overlay--vr .panorama-overlay__yt-video {
  &::before {
    backdrop-filter: blur(0.4rem);
    background-color: rgba(255, 255, 255, 0.4);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-extra-level-8;
  }
  &::after {
    color: $color-black;
    content: attr(data-vr);
    display: block;
    font-size: 1rem;
    font-weight: $font-weight-medium-500;
    left: 0;
    padding: 1rem 2rem;
    position: absolute;
    text-align: center;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
    z-index: $z-index-extra-level-9;
  }
  button {
    display: none;
  }

  .panorama-overlay__yt-video__loader-image__hint {
    font-size: 0.8rem;
  }
}

.panorama-overlay--vr .panorama-overlay__window__header {
  pointer-events: none;
  position: absolute;
  right: -4.5rem;
  width: 3.2rem;

  .panorama-overlay__window__closer {
    margin: 0;
    pointer-events: all;
    position: absolute;
    right: 0;
    transition-property: transform;
  }
}
.panorama-overlay--vr .panorama-overlay__window__controls {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  button:disabled {
    opacity: 0.3;
  }

  .panorama-overlay__prev-button,
  .panorama-overlay__next-button {
    bottom: -4.5rem;
    margin: 0;
    pointer-events: all;
    position: absolute;
    transition-property: transform;

    .panorama-viewer.panorama-viewer--vr-working.panorama-viewer--vr-overlay-single
      & {
      display: none;
    }
  }
  .panorama-overlay__prev-button {
    left: calc(50% - 1.6rem - 2rem);
  }
  .panorama-overlay__next-button {
    left: calc(50% - 1.6rem + 2rem);
  }
}

.panorama-overlay--vr .panorama-overlay__window__controls__vr {
  display: flex;
}

.panorama-overlay--vr-right {
  left: 50%;
}
