:root {
  --calendar-sheet-color: #{$color-blue-1};
}

.calendar-sheet {
  @include use-responsive-sizing(width height, responsive-map(xs 6.7rem, xl 9.5rem));

  align-items: center;
  border: 1px solid var(--calendar-sheet-color);
  color: var(--calendar-sheet-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.calendar-sheet__day {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.8rem, xl 4rem));

  font-weight: $font-weight-bold-700;
}

.calendar-sheet__month {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.2rem, xl 1.6rem));

  margin-top: 0.4rem;
}
