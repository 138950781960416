.image-teaser {
  --headline-color: #{$color-white};
  background-color: $color-blue-1;
  min-height: 25rem;
  padding-top: 56.25%;
  position: relative;
}

.image-teaser__image-container {
  display: flex;
  flex-direction: column;
  inset: 0;
  overflow: hidden;
  position: absolute;
  z-index: $z-index-foreground;
}

.image-teaser__image {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition-property: transform;

  .image-teaser:hover &,
  .image-teaser:focus-within & {
    transform: scale(1.05);
  }
}

.image-teaser__content {
  inset: 0;
  position: absolute;
  z-index: $z-index-extra-level-2;
  padding: var(--teaser-padding);
}

.image-teaser__duration {
  color: var(--headline-kicker-color, var(--headline-color));
  font-size: var(--headline-kicker-font-size, 1.6rem);
  line-height: $line-height-body;
  margin-left: 3.2rem;
  position: absolute;
  top: var(--teaser-padding);
  right: var(--teaser-padding);
  text-shadow: 0 0 3px rgba($color-blue-1, 0.5);
}

.image-teaser__headline {
  height: 100%;

  .headline,
  .headline__link {
    height: 100%;
  }

  .headline__kicker {
    padding-right: 10rem;
  }

  .headline__link {
    display: flex;
    flex-direction: column;
  }

  .headline__text {
    margin-top: auto;
  }

  .headline__kicker {
    text-shadow: 0 0 3px rgba($color-blue-1, 0.5);
  }
}
