/*!
 * Atruvia
 *
 * @copyright 2021 Dennis Morhardt, ressourcenmangel an der Panke GmbH
 */
@charset 'UTF-8';

/**
 * Vendors
 */
@import 'normalize.css';
@import 'sass-mq/mq';

/**
 * Abstracts
 */
@import 'abstracts/variables';
@import 'abstracts/font-definitions';
@import 'abstracts/functions';
@import 'abstracts/mixins';

/**
 * Base
 */
@import 'base/reset';
@import 'base/fonts';
@import 'base/root';
@import 'base/globals';
@import 'base/focus';
@import 'base/reduced-motion';
@import 'base/grid-overlay';

/**
 * Layouts
 */
@import 'layouts/page';

/**
 * Utils
 */
@import 'utils/utils';

/**
 * Particles
 */
@import '../components/0-particles/icon/icon';
@import '../components/0-particles/preview/preview';
@import '../components/0-particles/samp/samp';
@import '../components/0-particles/span/span';

/**
 * Atoms
 */
@import '../components/1-atoms/alert/alert';
@import '../components/1-atoms/button/button';
@import '../components/1-atoms/calendar-sheet/calendar-sheet';
@import '../components/1-atoms/copyright/copyright';
@import '../components/1-atoms/field/field';
@import '../components/1-atoms/headline/headline';
@import '../components/1-atoms/horizontal-rule/horizontal-rule';
@import '../components/1-atoms/icon-button/icon-button';
@import '../components/1-atoms/image/image';
@import '../components/1-atoms/legend/legend';
@import '../components/1-atoms/link/link';
@import '../components/1-atoms/list/list';
@import '../components/1-atoms/logo/logo';
@import '../components/1-atoms/message/message';
@import '../components/1-atoms/meta-bar/meta-bar';
@import '../components/1-atoms/pager/pager';
@import '../components/1-atoms/paragraph/paragraph';
@import '../components/1-atoms/pill/pill';
@import '../components/1-atoms/quote-slider-item/quote-slider-item';
@import '../components/1-atoms/quote-slider-item/quote-slider-item';
@import '../components/1-atoms/quote/quote';
@import '../components/1-atoms/table/table';
@import '../components/1-atoms/text-button/text-button';

/**
 * Molecules
 */
@import '../components/2-molecules/accordion/accordion';
@import '../components/2-molecules/article-breaker/article-breaker';
@import '../components/2-molecules/article-teaser/article-teaser';
@import '../components/2-molecules/breadcrumb/breadcrumb';
@import '../components/2-molecules/contact-teaser/contact-teaser';
@import '../components/2-molecules/content-guide/content-guide';
@import '../components/2-molecules/dropdown-navigation/dropdown-navigation';
@import '../components/2-molecules/dropdown-select/dropdown-select';
@import '../components/2-molecules/elc-cookie-consent/elc-cookie-consent';
@import '../components/2-molecules/event-teaser/event-teaser';
@import '../components/2-molecules/external-content/external-content';
@import '../components/2-molecules/facts/facts';
@import '../components/2-molecules/field-group/field-group';
@import '../components/2-molecules/field-table/field-table';
@import '../components/2-molecules/figure/figure';
@import '../components/2-molecules/full-gallery/full-gallery';
@import '../components/2-molecules/gallery/gallery';
@import '../components/2-molecules/highlight-block/highlight-block';
@import '../components/2-molecules/highlight-text/highlight-text';
@import '../components/2-molecules/html-embed/html-embed';
@import '../components/2-molecules/image-slide/image-slide';
@import '../components/2-molecules/image-teaser/image-teaser';
@import '../components/2-molecules/job-teaser/job-teaser';
@import '../components/2-molecules/link-box/link-box';
@import '../components/2-molecules/media-block/media-block';
@import '../components/2-molecules/new-work/new-work';
@import '../components/2-molecules/page-head/page-head';
@import '../components/2-molecules/pagination/pagination';
@import '../components/2-molecules/process/process';
@import '../components/2-molecules/quote-block/quote-block';
@import '../components/2-molecules/slide/slide';
@import '../components/2-molecules/upload-field/upload-field';
@import '../components/2-molecules/video-collection/video-collection';
@import '../components/2-molecules/video/video';

/**
 * Organisms
 */
@import '../components/3-organisms/alert-banner/alert-banner';
@import '../components/3-organisms/back-to-top/back-to-top';
@import '../components/3-organisms/career-entry/career-entry';
@import '../components/3-organisms/circle-graphic/circle-graphic';
@import '../components/3-organisms/field-group-repeater/field-group-repeater';
@import '../components/3-organisms/footer/footer';
@import '../components/3-organisms/form-wrapper/form-wrapper';
@import '../components/3-organisms/form/form';
@import '../components/3-organisms/header/header';
@import '../components/3-organisms/image-slider/image-slider';
@import '../components/3-organisms/infobox/infobox';
@import '../components/3-organisms/job-search-bar/job-search-bar';
@import '../components/3-organisms/lines-graphic/lines-graphic';
@import '../components/3-organisms/logo-grid/logo-grid';
@import '../components/3-organisms/modal/modal';
@import '../components/3-organisms/page-content/page-content';
@import '../components/3-organisms/panorama-overlay/panorama-overlay';
@import '../components/3-organisms/panorama-viewer/panorama-viewer';
@import '../components/3-organisms/pill-navigation/pill-navigation';
@import '../components/3-organisms/pyramid-graphic/pyramid-graphic';
@import '../components/3-organisms/quote-slider/quote-slider';
@import '../components/3-organisms/ribbon-slider/ribbon-slider';
@import '../components/3-organisms/split-view/split-view';
@import '../components/3-organisms/stage/stage';
@import '../components/3-organisms/tabs/tabs';
@import '../components/3-organisms/teaser-grid/teaser-grid';
@import '../components/3-organisms/teaser-list/teaser-list';
@import '../components/3-organisms/topic/topic';

/**
 * Themes and overwrites
 */

@import 'themes/themes';
@import 'themes/gb2021';
