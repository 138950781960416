.tabs {
  background-color: $color-blue-3;
  color: $color-blue-1;
  overflow: hidden;
}

.tabs__inner {
  @include use-container($start-breakpoint: m);

  @include mq($from: m) {
    display: flex;
  }
}

.tabs__tabs {
  @include use-responsive-sizing(
    width,
    (
      m: column-width(m, 4),
      l: column-width(l, 4),
      xl: column-width(xl, 4),
    )
  );

  display: none;

  @include mq($from: m) {
    align-self: flex-start;
    display: block;
    flex-shrink: 0;
    padding: 4rem 0;
  }
}

.tabs__tab {
  display: block;
  font-size: 1.8rem;
  line-height: $line-height-body;

  & + & {
    margin-top: 1.6rem;
  }

  @include mq($from: m) {
    text-align: right;
  }
}

.tabs__tab-link {
  display: block;
  opacity: 0.6;
  padding-right: 4rem;
  position: relative;
  transition-property: opacity;

  &[aria-selected='true'] {
    font-weight: $font-weight-medium-500;
    opacity: 1;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }

  &[aria-selected='true']::after {
    border-color: transparent transparent transparent $color-blue-3;
    border-style: solid;
    border-width: 17.5px 0 17.5px 20px;
    content: '';
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: $z-index-extra-level-3;
  }
}

.tabs__panels {
  @include use-responsive-sizing(
    padding-top padding-bottom,
    responsive-map(m 4rem, xl 5.6rem),
    $start-breakpoint: m
  );

  flex-grow: 1;
  position: relative;

  @include mq($from: m) {
    --headline-color: #{$color-white};
    --paragraph-color: #{$color-white};

    align-items: center;
    background-color: $color-blue-1;
    color: $color-white;
    display: flex;
    padding: 4rem 0;
    padding-left: 7.2rem;

    &::after {
      background-color: $color-blue-1;
      bottom: 0;
      content: '';
      left: 100%;
      position: absolute;
      top: 0;
      width: 100vw;
    }
  }
}

.tabs__panel {
  position: relative;
  & + & {
    border-top: 1px solid $color-blue-1;
  }

  @include mq($from: m) {
    display: none;
    &.tabs__panel--open {
      display: block;
    }
  }
}

.tabs__panel-trigger {
  @include use-container($end-breakpoint: s, $scoped: true);

  padding-bottom: 3.2rem;
  padding-top: 3.2rem;
  text-align: left;

  @include mq($from: m) {
    display: none;
    pointer-events: none;

    .tabs__panel--open & {
      display: block;
    }
  }
}

.tabs__panel-content {
  --headline-color: #{$color-white};
  --paragraph-color: #{$color-white};
  --list-color: #{$color-white};
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  background-color: $color-blue-1;
  color: $color-white;
  margin-bottom: -1px;
  padding-bottom: 3.2rem;
  padding-top: 4.8rem;
  position: relative;

  &::before {
    @include use-responsive-sizing(left, $inner-container-padding);

    border-color: $color-blue-3 transparent transparent transparent;
    border-style: solid;
    border-width: 20px 17.5px 0;
    content: '';
    position: absolute;
    top: 0;

    @include mq($from: m) {
      display: none;
    }
  }

  &[aria-hidden='true'] {
    display: none;
  }

  @include mq($from: m) {
    padding-top: 0;
  }
}

.tabs__panel-content-inner {
  @include use-container($end-breakpoint: s, $scoped: true);

  > * + * {
    margin-top: 2rem;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.tabs__panel-button {
  @include use-responsive-sizing(
    margin-top,
    responsive-map(xs 3.2rem, xl 5.6rem)
  );
}
