@import 'glightbox/dist/css/glightbox.min.css';

.video-collection {
  --headline-color: #{$color-white};
  color: $color-white;
  background-color: $color-blue-1;
  padding-top: 64px;
  padding-bottom: 64px;

  @include mq($from: xl) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .video-collection__headline {
    @include use-container();
    margin-bottom: 48px;

    @include mq($from: l) {
      margin-bottom: 80px;
      text-align: center;
    }
  }

  .video-collection__button {
    @include use-container();
    @include mq($from: m) {
      text-align: center;
    }

    a.button {
      margin-top: 24px;
      margin-bottom: 0;

      @include mq($from: l) {
        margin-top: 48px;
      }
    }
  }
  .video-collection__container {
    .video-collection__items {
      @include use-container();

      display: grid;
      column-gap: 16px;
      overflow-x: auto;

      @include mq($from: m) {
        column-gap: 30px;
      }

      &.video-collection__grid- {
        &2 {
          grid-template-columns: repeat(2, minmax(240px, 1fr));
        }

        &3 {
          grid-template-columns: repeat(3, minmax(240px, 1fr));
        }

        &4 {
          grid-template-columns: repeat(4, minmax(240px, 1fr));
        }
      }
    }

    .video-collection__item {
      overflow: hidden;
    }

    .video-collection__item-title {
      --paragraph-color: #{$color-white};
      @include font-paragraph;
      margin-top: 24px;
      margin-bottom: 24px;

      @include mq($from: m) {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }

    .video-collection__item-play-icon {
      background-color: $color-blue-2;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      margin-top: -50px;
      bottom: 25px;
      position: relative;
      margin-left: 25px;
      align-items: center;
      justify-content: center;
      transition: 0.1s all ease-in;

      svg {
        width: 25px;
        height: auto;
        transition: 0.2s all ease-in-out;
      }
    }
  }

  .animation-thumbnailZoom {
    figure {
      overflow: hidden;

      img {
        transition: $duration-long transform ease-in-out;
      }

      &:hover img {
        transform: scale(1.03);
      }
    }
  }

  .animation-titleUnderline {
    .video-collection__item:hover {
      .video-collection__item-title {
        text-decoration: underline;
        animation: underlineAnimation 0.3s ease-in-out forwards;
      }
    }
  }
}

.goverlay,
.glightbox-mobile .goverlay {
  background-color: $color-white;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-description {
  background: transparent;
  position: relative;
  padding: 0;

  @include mq($until: m) {
    @include use-container;
  }
}

.glightbox-container .ginner-container {
  height: auto;
}

.glightbox-container .gslider {
  padding-top: 72px;
  height: auto;

  @include mq($from: m) {
    @include use-container;
  }
}
body.glightbox-open {
  height: auto !important; // override the default glightbox definition to prevent scrolling to the documents top
}

.glightbox-clean {
  .gslide-media {
    box-shadow: none;
  }

  .gslide-description {
    @include font-paragraph;

    background-color: transparent;
    color: $color-blue-1;

    .gslide-title {
      --font-weight: $font-weight-bold-500;
      @include font-paragraph-large;

      margin-top: 16px;
    }
  }

  .gdesc-inner {
    padding: 20px 0;
  }

  button {
    &.gprev,
    &.gnext {
      display: none;
    }

    &.gclose {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      path {
        fill: $color-black;
      }
    }
  }
}

@keyframes underlineAnimation {
  from {
    text-decoration-color: transparent;
  }
  to {
    text-decoration-color: inherit;
  }
}
