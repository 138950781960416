.headline {
  --underline-offset: 0.17em;
}

.headline--1 {
  @include font-headline-1;
}

.headline--2 {
  @include font-headline-2;
}

.headline--3 {
  @include font-headline-3;
}

.headline--4 {
  @include font-headline-4;
}

.headline--5 {
  @include font-headline-5;
}

// force bold headline for h1 and h3
.headline__highlight {
  --headline-font-weight: #{$font-weight-medium-500};
  font-weight: var(--headline-font-weight);
}

.headline__link {
  display: block;
}

.headline__kicker {
  color: var(--headline-kicker-color, currentColor);
  display: block;
  font-size: var(--headline-kicker-font-size, 1.6rem);
  font-weight: var(--headline-kicker-font-weight, bold);
  line-height: $line-height-body;
  margin-bottom: var(--headline-kicker-margin, 0.8rem);
  text-transform: none;
}
