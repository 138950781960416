.back-to-top {
  position: fixed;
  top: 80%;
  right: 0;
  z-index: $z-index-upper;
  height: 64px;
  width: 64px;
  padding: 18px 26px 18px 22px;
  background-color: $color-blue-1;
  color: $color-white;
  border-top-left-radius: 32px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.is-visible {
    @include mq($from: m) {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__icon {
    font-size: 24px;
  }
}
